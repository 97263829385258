import dynamic from 'next/dynamic';
import UrlPattern from 'url-pattern';
import { routers, cities as routerCities } from '@/helpers/router/config.js';

export const getRootUrl = (lang) => (lang !== 'en' ? `/${lang}` : '');

export const getStaticPaths = () => routers.reduce((acc, path) => {
  let newAcc = [...acc];
  if (!path.ignore && !path.noPath) {
    newAcc = newAcc.concat(path.staticPath);
  }
  return newAcc;
}, []);

export const getConfigByPath = ({ locale, resolvedUrl }) => routers.find((p) => {
  try {
    if (p.ignore) return false;
    const matchpattern = new UrlPattern(p.pattern);
    const resmatch = matchpattern.match(resolvedUrl);

    if (!resmatch) return false;
    const { staticPath } = p;
    const path = staticPath.find((sp) => sp.locale === locale);
    if (!path) return false;

    const keys = Object.keys(resmatch);
    const matched = keys.reduce((acc, k) => {
      if (path.params[k]) {
        if (path.params[k] === resmatch[k]) acc.push(true);
        else acc.push(false);
      }
      return acc;
    }, []);
    return !matched.includes(false);
  } catch (e) {
    // console.log('error router getConfigByPath', locale, resolvedUrl)
    return false;
  }
});

export const getComponentByPath = (componentPage) => dynamic(() => import(`@/components/pages/${componentPage}.js`));

/*
values => {route: '', query:[]} will override params.params for compile new url
*/
export const getPathByLang = (name, locale, { slug, ...values } = {}) => {
  try {
    let config;
    if (typeof name === 'string') {
      config = routers.find((r) => {
        if (!slug) return r.name === name;
        if (slug && r.name !== name) return false;
        if (r?.staticPath) {
          return !!r?.staticPath.find((staticPath) => staticPath?.params?.slug === slug);
        }
        return false;
      });
    } else {
      config = name; // typeof Object
    }

    if (!config) return getRootUrl(locale);
    const urlpattern = new UrlPattern(config.pattern || '(:toto)');
    const params = config.staticPath.find((sp) => sp.locale === locale);
    if (params.externalUrl) return `${params.externalUrl}${(config.pattern ? urlpattern.stringify(values) : '')}`;
    const newValues = { ...(!params ? {} : params.params), ...values };
    const patternstring = urlpattern.stringify(newValues);
    return `${getRootUrl(locale)}${patternstring === '/' ? '' : patternstring}`;
  } catch (e) {
    // console.log('warning router getPathByLang ',name, locale, values)
    return getRootUrl(locale);
  }
};

export const switchPageByLang = (resolvedUrl, locale, lang) => {
  try {
    const config = getConfigByPath({ resolvedUrl, locale });
    if (!config) return getRootUrl(lang);
    // let urlpattern = new UrlPattern(config.pattern)
    // let resmatch = urlpattern.match(resolvedUrl)
    // delete resmatch.route
    return getPathByLang(config, lang);
  } catch (e) {
    // console.log('warning router switchPageByLang', resolvedUrl, locale, lang, values)
    return getRootUrl(lang);
  }
};

export const getCanonicalUrl = (namepath, langCurrent, options = {}) => {
  const urls = [
    {
      rel: 'canonical',
      href: process.env.NEXT_PUBLIC_URL_V3 + getPathByLang(namepath, langCurrent, options),
    },
    {
      rel: 'alternate',
      hrefLang: 'x-default',
      href: process.env.NEXT_PUBLIC_URL_V3 + getPathByLang(namepath, 'fr', options),
    },
  ];
  ['en', 'fr', 'es'].map((lang) => urls.push({
    rel: 'alternate',
    hrefLang: lang,
    href: process.env.NEXT_PUBLIC_URL_V3 + getPathByLang(namepath, lang, options),
  }));
  return urls;
};

export { routerCities };
